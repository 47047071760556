<script>
import Coder from '@/components/Coder/Coder.vue'

export default {
  name: 'WebhookConfigDoc',
  components: { Coder },
  data () {
    return {
      postData: JSON.stringify({
        event_type: 'interface_verification',
        message_id: '20241014000000000672',
        message: 'This message is used to verify domain name and interface ownership.'
      }, null, 4)
    }
  }
}
</script>

<template>
  <div style="width: 100%;padding: 16px;">
    <div>After you click the verification button, the server will send a POST request using the HTTPS URL you configured. The data format is application/json. The example is as follows:</div>
    <div>
      <div style="margin-top: 8px;">
        <coder :code="postData"></coder>
      </div>
    </div>
    <div>After receiving the verification data, the interface needs to return message_id to complete the verification.</div>
  </div>
</template>

<style scoped lang='less'>

</style>
