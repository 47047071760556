<script>
import Coder from '@/components/Coder/Coder.vue'
import WebhookConfig from '@/views/data/modal/WebhookConfig.vue'
import STable from '@/components/Table/index'
import {
  loadAdminCurrentAmount,
  loadAlcSensorWebhookConfig, loadAlcWebhookRecord,
  sendMockAlcSensorWebhookData,
  switchEnableAlcSensorWebhookConfig
} from '@/api/data'
import PayAmount from '@/views/data/modal/PayAmount.vue'
import moment from 'moment'

export default {
  name: 'WebHook',
  components: {
    Coder,
    STable
  },
  data () {
    return {
      queryStatus: 999,
      enable: false,
      queryParams: {},
      height: document.body.clientHeight - 216,
      keyShow: false,
      config: null,
      visible: true,
      mAmount: 0,
      postData: JSON.stringify({
        event_type: 'test',
        message_id: '20241014000000000672',
        message: 'This is real data, please save it to your database.',
        signature: 'c516e08d82bffd95a4f655b35aa4c1fc',
        data: {
          record_id: 123456789,
          user_id: 999999999,
          user_name: '',
          test_device: '812000036CD2',
          test_result: 0.333,
          result_unit: 'mg/L',
          test_timestamp: 1728879963000
        }
      }, null, 4),

      columns: [{
        title: this.$t('api.field.key'),
        dataIndex: 'key',
        width: 160,
        align: 'center'
      },
        {
          title: this.$t('api.field.type'),
          dataIndex: 'type',
          width: 100
        },
        {
          title: this.$t('api.field.desc'),
          dataIndex: 'desc'
        }],
      data: [{
        key: 'event_type',
        type: 'string',
        desc: 'The current possible values are test or mock. Test indicates that the data is official user testing data, while mock indicates that the data is simulated testing data.'
      },
        {
          key: 'message_id',
          type: 'string',
          desc: 'Message ID, unique for each message.'
        },
        {
          key: 'message',
          type: 'string',
          desc: 'Message prompt text.'
        },
        {
          key: 'signature',
          type: 'string',
          desc: 'Message signature, this field is used to verify the authenticity of the message. The algorithm is MD5 (message_id+signture_key), where signture_key is the signature key generated when configuring HTTPS URLs. For example, message_id is 2024101400000000672, and signture_key is v8ANarkc2bOwiTAPlnZUG5WZve8OJDPeC1UgWrmb6iogFtQg. After splicing the two, the final value is 2024101400000000672v8ANarkc2bOwiTAPlnZUG5WZve8OJDPeC1UgWrmb6iogFtQg, followed by MD5. The final value is c516e08d82bff95a4f655b35aa4c1fc. The server needs to verify whether the value is correct to investigate malicious sending of junk data by others.'
        },
        {
          key: 'data.record_id',
          type: 'int',
          desc: 'Record ID'
        },
        {
          key: 'data.user_id',
          type: 'int',
          desc: 'User ID'
        }, {
          key: 'data.user_name',
          type: 'string',
          desc: 'User name'
        }, {
          key: 'data.test_device',
          type: 'string',
          desc: 'The serial number of the device used by the user.'
        }, {
          key: 'data.test_result',
          type: 'float',
          desc: 'User testing result.'
        }, {
          key: 'data.result_unit',
          type: 'string',
          desc: 'Measurement unit for test results. Consistent with the configuration settings.'
        }, {
          key: 'data.test_timestamp',
          type: 'long',
          desc: 'Timestamp of testing time.'
        }],
      columns2: [{
        title: 'Message ID',
        dataIndex: 'message_id',
        align: 'center',
        width: 200
      }, {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        width: 80,
        scopedSlots: { customRender: 'status' }
      },
        {
          title: 'Message Content',
          dataIndex: 'push_content'
        },
        {
          title: 'Message Time',
          dataIndex: 'create_time',
          align: 'center',
          width: 160
        },
        {
          title: 'Action',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
          width: 120
        }],
      loadData: params => {
        return loadAlcWebhookRecord(Object.assign(this.queryParams, params)).then(res => {
          const data = res.result.data
          for (const item of data) {
            item.create_time = moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')
            item.push_content = decodeURIComponent(item.push_content)
          }
          return res.result
        })
}
    }
  },
  created () {
    this.loadCurrentAmount()
    this.loadConfig()
  },
  methods: {
    handelChangeStatus (e) {
      this.queryStatus = e
      if (e !== 999) {
        this.queryParams.status = e
      } else {
        this.queryParams.status = null
      }
      this.$refs.table.refresh(true)
    },
    handleClose () {
      this.visible = false
      this.height = document.body.clientHeight - 186
    },
    startConfig () {
      this.showModal(WebhookConfig, 'Webhook configuration', 720, 440)
    },
    editConfig () {
      this.showModal(WebhookConfig, 'Webhook configuration', 720, 440, { config: this.config })
    },
    loadConfig () {
      loadAlcSensorWebhookConfig().then(res => {
        if (res.code === 0) {
          this.config = res.data
          this.enable = res.data.enable_tag === 1
        }
      })
    },
    switchKeyShow () {
      this.keyShow = !this.keyShow
    },
    mock () {
      sendMockAlcSensorWebhookData().then(res => {
        this.$message.success(this.$t('user.webhook.config.mock.sended'))
      })
    },
    handleChangeEnable (e) {
      this.enable = e
      switchEnableAlcSensorWebhookConfig({ enable_tag: e ? 1 : 0 }).then(res => {

      })
    },
    charge () {
      this.showModal(PayAmount, this.$t('user.webhook.charge'), 700, 300)
    },
    loadCurrentAmount () {
      loadAdminCurrentAmount().then(res => {
         if (res.code === 0) {
           this.mAmount = res.data
         }
      })
    }
  }
}
</script>

<template>
  <div>
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" :tab="$t('user.webhook.tab.config')">
        <div>
          <a-alert
            v-if="visible"
            :message="$t('user.webhook.function.tip')"
            type="warning"
            closable
            :after-close="handleClose"
          />
        </div>
        <div style="margin-top: 8px;">
          <a-row :gutter="12">
            <a-col :lg="12">
              <!---setting---->
              <div>
                <div class="ant-card" style="padding: 8px;">
                  <div style="display: flex;">
                    <div style="line-height: 32px;flex:1;"><span>{{ $t('user.webhook.remaining.amount') }}</span><span
                      style="color: #52c41a;">{{ mAmount }}</span><span style="margin-left: 8px;">JPY</span></div>
                    <div>
                      <a-button type="primary" style="margin-left: 8px;" @click="charge">{{ $t('user.webhook.charge') }}</a-button>
                    </div>
                  </div>
                  <div style="margin-top: 8px;"><span
                    style="color:#999;font-size: 0.8rem;">{{ $t('user.webhook.remaining.amount.tip') }}</span></div>
                </div>

                <div class="ant-card" style="padding: 8px;margin-top: 16px;min-height: 400px;position: relative;">

                  <div v-if="config" style="">
                    <div class="header-title">
                      <div class="header-label">{{ $t('user.webhook.config,info') }}</div>
                      <div class="header-action">
                        <a-switch
                          v-model="enable"
                          @change="handleChangeEnable"
                          :checked-children="$t('user.webhook.config.enable')"
                          :un-checked-children="$t('user.webhook.config.disable')"></a-switch>
                      </div>
                    </div>
                    <div class="list">
                      <div class="list-item">
                        <div class="list-item-title">{{ $t('user.webhook.config.url') }}</div>
                        <div class="list-item-desc">{{ config.push_url }}</div>
                      </div>
                      <div class="list-item">
                        <div class="list-item-title">{{ $t('user.webhook.config.key') }}</div>
                        <div class="list-item-desc" style="display: flex;">
                          <div style="flex: 1;">
                            {{ keyShow ? config.signature_key : '************************************************' }}
                          </div>
                          <a
                            @click="switchKeyShow">{{ keyShow ? $t('user.webhook.config.key.hide') : $t('user.webhook.config.key.show')
                          }}</a>
                        </div>
                      </div>
                      <div class="list-item">
                        <div class="list-item-title">{{ $t('user.webhook.config.unit') }}</div>
                        <div class="list-item-desc">{{ config.unit_type == 1 ? 'mg/L' : '%' }}</div>
                      </div>
                    </div>
                    <div style="padding: 8px;color:darkorange;">{{ $t('user.webhook.config.tip5') }}</div>
                    <div style="position: absolute;bottom:8px;left:0;width: 100%;text-align: center;">
                      <a-button @click="mock">{{ $t('user.webhook.config.mock') }}</a-button>
                      <a-button class="margin-left-16" @click="editConfig">{{ $t('user.webhook.config.update') }}</a-button>
                    </div>
                  </div>
                  <div class="empty-container" v-else>
                    <div class="empty-container-inner">
                      <div>{{ $t('user.webhook.config.empty') }}</div>
                      <div style="margin-top: 8px;">
                        <a-button type="primary" @click="startConfig">{{ $t('user.webhook.config') }}</a-button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </a-col>
            <a-col :lg="12">
              <!---document---->
              <div class="ant-card scroll-2" style="padding: 8px;overflow: auto;" :style="{height:height+'px'}">
                <div>{{ $t('user.webhook.function.tip1') }}</div>
                <div style="margin-top: 8px;">{{ $t('user.webhook.function.tip2') }}</div>
                <div style="margin-top: 8px;">{{ $t('user.webhook.function.tip3') }}</div>
                <div style="margin-top: 8px;">
                  <coder :code="postData"></coder>
                </div>
                <div style="margin-top: 12px;">{{ $t('user.webhook.function.tip6') }}</div>
                <div style="margin-top: 12px;">{{ $t('user.webhook.interface.json.reark') }}</div>
                <div>
                  <a-table :columns="columns" :data-source="data" :pagination="false">
                    <a slot="name" slot-scope="text">{{ text }}</a>
                  </a-table>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="$t('user.webhook.tab.record')" force-render>
        <div >
          <div style="padding: 8px;" class="ant-card">
            <span style="line-height: 32px;margin-right: 8px;">Status:</span>
            <a-select style="width: 120px;" v-model="queryStatus" @change="handelChangeStatus">
              <a-select-option :value="999">All</a-select-option>
              <a-select-option :value="0">Sending</a-select-option>
              <a-select-option :value="1">Success</a-select-option>
              <a-select-option :value="2">Fail</a-select-option>
            </a-select>
          </div>
          <div style="margin-top: 8px;" class="ant-card">
            <s-table
              :columns="columns2"
              ref="table"
              :data="loadData"
              bordered
              size="small"
              :scroll="{x:1100}">
              <div slot="status" slot-scope="text, record">
                <span v-if="record.status==0">Sending</span>
                <span v-if="record.status==1">Success</span>
                <span v-if="record.status==2">Fail</span>
              </div>
              <div slot="action" slot-scope="text, record">
                <span v-if="record.status==2"><a>Retry</a></span>
                <span v-else>-</span>
              </div>
            </s-table>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>

  </div>
</template>

<style scoped lang='less'>
.empty-container {
  width: 100%;
  display: table;

  .empty-container-inner {
    display: table-cell;

    height: 400px;
    vertical-align: middle;
    text-align: center;
  }
}

.header-title {
  display: flex;
  padding: 8px;

  .header-label {
    font-weight: bold;
    flex: 1;
    line-height: 32px;
  }

}

.list {
  .list-item {
    padding: 8px;
    line-height: 24px;
    border-top: 1px solid #e8e8e8;

    .list-item-title {
      color: #000
    }

    .list-item-label {
      color: #444;
    }
  }

  .list-item:last-child {
    border-bottom: 1px solid #e8e8e8;
  }
}
</style>
