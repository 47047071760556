<script>
import { loadScript } from '@paypal/paypal-js'
import { captureMembershipOrder, checkOrderStatus, createMembershipOrder } from '@/api/data'

export default {
  name: 'PayAmount',
  props: ['layerid'],
  data () {
    return {
      checkedAmounts: 500,
      plainOptions: [
        { label: '100JPY', value: 100 },
        { label: '200JPY', value: 200 },
        { label: '500JPY', value: 500 },
        { label: '1000JPY', value: 1000 },
        { label: '2000JPY', value: 2000 },
        { label: '4000JPY', value: 4000 },
        { label: '8000JPY', value: 8000 },
        { label: this.$t('user.amount.charge.custom'), value: 0 }
      ],
      customAmounts: 400,
      paySuccess: false,
      payShow: false
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  created () {
    const _this = this
    loadScript({
      // 'environment': 'sandbox',
      // 'client-id': 'Aea6Z6vCwKjwTXVaTKa2268YxNwo5Kja9lV_Tcm04zVeWYEXMrO316y5es7NnSwYvmhietyuQHMSP1bS',
      'client-id': 'AV2loXix91fcINCOXVhq9jnl289U1V8PH5ANYmVdnI4FmPZ7hEhoLUyX0blYxG5b7G59pM7E7JVpfpCk',
      'currency': 'JPY'
    })
      .then((paypal) => {
        paypal.Buttons({
            async createOrder () {
              try {
                const orderData = await createMembershipOrder({
                  num: _this.checkedAmounts || _this.customAmounts,
                  type: 3,
                  uid: 0
                })

                if (orderData.id) {
                  return orderData.id
                } else {
                  const errorDetail = orderData?.details?.[0]
                  const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData)

                  throw new Error(errorMessage)
                }
              } catch (error) {
                console.error(error)
              }
            },
            async onApprove (data, actions) {
              if (_this.timer) {
                clearInterval(_this.timer)
                _this.timer = null
              }
              _this.timer = setInterval(() => {
                checkOrderStatus({ orderId: data.orderID }).then(res => {
                  if (res.code === 0 && res.data === 1) {
                    _this.paySuccess = true
                    clearInterval(_this.timer)
                    setTimeout(function () {
                      _this.$parent.loadCurrentAmount()
                      _this.$layer.close(_this.layerid)
                    }, 2000)
                  }
                })
              }, 2000)
              try {
                const orderData = await captureMembershipOrder({
                  orderId: data.orderID
                })
                // Three cases to handle:
                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                //   (2) Other non-recoverable errors -> Show a failure message
                //   (3) Successful transaction -> Show confirmation or thank you message

                const errorDetail = orderData?.details?.[0]

                if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
                  // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                  return actions.restart()
                } else if (errorDetail) {
                  // (2) Other non-recoverable errors -> Show a failure message
                  throw new Error(`${errorDetail.description} (${orderData.debug_id})`)
                } else if (!orderData.purchase_units) {
                  throw new Error(JSON.stringify(orderData))
                } else {
                  console.log(
                    'Capture result',
                    orderData,
                    JSON.stringify(orderData, null, 2)
                  )
                  if (orderData.status === 'COMPLETED') {
                    _this.paySuccess = true
                    clearInterval(_this.timer)
                    setTimeout(function () {
                      _this.$parent.loadCurrentAmount()
                      _this.$layer.close(_this.layerid)
                    }, 2000)
                  }
                }
              } catch (error) {
                console.error(error)
              }
            }
          }
        ).render('#paypal-button-container')
      })
      .catch((err) => {
        console.error('failed to load the PayPal JS SDK script', err)
      })
  },
  methods: {
    cancel () {
      this.$layer.close(this.layerid)
    },
    onClose () {
      this.payShow = false
    },
    pay () {
      this.payShow = true
    }
  }
}
</script>

<template>
  <div style="width: 100%;padding: 8px 16px;">
    <div>
      <div>{{ $t('user.amount.buy.num.tip') }}</div>
      <div style="margin-top: 12px;">
        <a-radio-group button-style="solid" v-model="checkedAmounts">
          <a-radio-button :value="item.value" v-for="item in plainOptions">
            {{ item.label }}
          </a-radio-button>
        </a-radio-group>
      </div>
      <div style="margin-top: 28px;" v-if="checkedAmounts === 0">{{ $t('user.amount.charge.custom') }}:<a-input-number v-model="customAmounts" :step="100" :min="100" style="width: 120px;margin-left: 8px;" /><span style="margin-left: 8px;">JPY</span></div>

      <div style="position: absolute;width: 95%;bottom:8px;overflow: hidden;">
        <div class="float-left" style="line-height:32px;color:#0099FF">{{ $t('user.amount.buy.pay') }}{{ (checkedAmounts||customAmounts) }} JPY</div>
        <div class="float-right"><a-button @click="cancel">{{ $t('user.action.cancel') }}</a-button><a-button type="primary" class="margin-left-16" @click="pay">{{ $t('user.amount.buy.submit') }}</a-button></div>
      </div>
    </div>
    <a-drawer
      title=""
      placement="right"
      :width="300"
      :closable="true"
      :visible="payShow"
      :get-container="false"
      @close="onClose"
    >
      <div style="padding-top: 40px;">
        <div style="line-height: 40px;text-align: center;">支払い{{ checkedAmounts||customAmounts }}JPY</div>
        <div v-if="paySuccess" style="text-align: center">
          <a-result
            status="success"
            title="支払いに成功しました"
          ></a-result>
          <div></div>
        </div>
        <div id="paypal-button-container" v-else></div>
      </div>

    </a-drawer>
  </div>
</template>

<style scoped>

</style>
