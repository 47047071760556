<script>
import { checkAlcSensorWebhookConfig, saveAlcSensorWebhookConfig } from '@/api/data'
import WebhookConfigDoc from '@/views/data/modal/WebhookConfigDoc.vue'

export default {
  name: 'WebhookConfig',
  data () {
    return {
       form: this.$form.createForm(this),
       passUrl: null
    }
  },
  props: ['config', 'layerid'],
  created () {
    const _this = this
    setTimeout(function () {
      let obj = null
      if (_this.config) {
        obj = _this.config
        _this.passUrl = obj.push_url
      } else {
        obj = {
          signature_key: _this.genRandomCode(48)
        }
      }
      _this.form.setFieldsValue(obj)
    }, 1200)
  },
  methods: {
    check () {
      this.form.validateFields(['push_url'], (errors, values) => {
        if (!errors) {
          checkAlcSensorWebhookConfig({
            push_url: values.push_url
          }).then(res => {
             if (res.data) {
               this.passUrl = values.push_url
               this.$message.success(this.$t('user.webhook.config.check.success'))
             } else {
               this.$message.error(this.$t('user.webhook.config.check.fail'))
             }
          }).catch(e => {

          })
        }
      })
    },
    showDoc () {
       this.showModal(WebhookConfigDoc, 'Document', 700, 360)
    },
    resetKey () {
      this.form.setFieldsValue({
        signature_key: this.genRandomCode(48)
      })
    },
    genRandomCode (num) {
      var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      var code = ''
      for (var i = 0; i < num; i++) {
        code += chars[parseInt(Math.random() * chars.length)]
      }
      return code
    },
    handleSubmit (e) {
      e.preventDefault()

      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.passUrl !== values.push_url) {
            this.$message.error(this.$t('user.action.submit.url.error'))
          } else {
            saveAlcSensorWebhookConfig(values).then(res => {
               if (res.code === 0) {
                 this.$parent.loadConfig()
                 this.$layer.close(this.layerid)
               }
            })
          }
        }
      })
    }
  }
}
</script>

<template>
  <div style="width: 100%;padding: 16px;">
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" @submit="handleSubmit">
      <a-form-item :label="$t('user.webhook.config.url')">
        <a-row :gutter="12">
          <a-col :span="21">
            <a-input
              :placeholder="$t('user.webhook.config.tip')"
              v-decorator="['push_url', { rules: [{ required: true,pattern: /^(https:)(\/\/[a-zA-Z0-9\-\.]+)(:[0-9]+)?(\/[^#\s]*)(#[^#\s]*)?$/, message: $t('user.webhook.config.tip') }] }]"
            />
          </a-col>
          <a-col :span="3">
            <a-button @click="check" style="width: 100%;">{{ $t('user.webhook.config.check') }}</a-button>
          </a-col>
        </a-row>

      </a-form-item>
      <div><a-row><a-col :xs="{offset:5}"><span>{{ $t('user.webhook.config.tip3') }}</span><a @click="showDoc">{{ $t('user.webhook.config.check.doc') }}</a><span>{{ $t('user.webhook.config.tip4') }}</span></a-col></a-row></div>
      <a-form-item :label="$t('user.webhook.config.key')">
        <a-row :gutter="12">
          <a-col :span="20">
            <a-input
              disabled=""
              v-decorator="['signature_key', { rules: [{ required: true, message: '' }] }]"
            />
          </a-col>
          <a-col :span="4">
            <a-button style="width: 100%;" @click="resetKey">{{ $t('user.webhook.config.key.gen') }}</a-button>
          </a-col>
        </a-row>

      </a-form-item>
      <a-form-item :label="$t('user.webhook.config.unit')">
        <a-select
          style="width: 100%"
          v-decorator="[
            'unit_type',
            { rules: [{ required: true, message: $t('user.webhook.config.unit.tip') }] },
          ]"
          :placeholder="$t('user.webhook.config.unit.tip')"
        >
          <a-select-option :value="1">
            mg/L
          </a-select-option>
          <a-select-option :value="2">
            %
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button type="primary" html-type="submit">
          {{ $t('user.action.submit') }}
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<style scoped lang='less'>

</style>
